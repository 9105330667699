.hero-beire {
  margin-top: 20px;
  margin-left: 10px;

  .clean-title {
    font-size: 3rem;

    @media (max-width: 853px) {
      font-size: 2.5rem;
    }

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }

    @media (max-width: 400px) {
      padding-top: 10px;
      font-size: 2rem;
    }

    @media (max-width: 320px) {
      padding-top: 10px;
      font-size: 1.5rem;
    }
  }

  .action {
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .subtitle {
    margin-top: 10px;

    @media (max-width: 400px) {
      font-size: 1.2rem;
    }
  }

  .hero-image {
    margin-top: 80px;

    @media (max-width: 400px) {
      margin-top: 10px;
    }
  }
}

.navbar-brand {
  height: 60px;
}

.title-container {
  background-color: #157df8;

  padding: 50px 10px;
}

.small-padding {
  padding: 4rem 1.5rem !important;
}
