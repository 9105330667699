/*! _layout-utils.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Bulkit utils
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Resets and base setup
1. Back to top button
2. Progress bars
3. Input placeholders
4. Window scrollbar
5. Demo link colors
=============================================================================
***/

/* ==========================================================================
0. Resets and base setup
========================================================================== */

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    overflow: hidden !important;
}

a,
a:active,
i,
i:active,
div,
div:active,
div:focus,
span:focus,
span:active,
    {
    outline: none !important;
}

.button.is-active,
.button:active,
.button:focus {
    box-shadow: none !important
}

section:focus {
    outline: none !important;
}

@font-face {
    font-family: 'Nexa Light';
    src: url("../fonts/text/nexa/NexaLight.woff") format("woff"), url("../fonts/text/nexa/NexaLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Nexa Bold';
    font-weight: bold;
    src: url("../fonts/text/nexa/NexaBold.woff") format("woff"), url("../fonts/text/nexa/NexaBold.ttf") format("truetype");
}


/* ==========================================================================
1. Back to top button
========================================================================== */

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 230px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);

}

#backtotop.visible a:hover {
    outline: none;
    opacity: 0.9;
    background: #38b7ff;
}

#backtotop a {
    outline: none;
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $medium-grey;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px
}

body #backtotop a {
    outline: none;
    color: #fff;
}

#backtotop a:after {
    outline: none;
    content: "\f106";
    font-family: "FontAwesome";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
}


/* ==========================================================================
2. Progress bars
========================================================================== */

.progress {
    width: 100% !important;
}

.progress.primary::-webkit-progress-value {
    background-color: $primary !important;
}

.progress.secondary::-moz-progress-bar {
    background-color: $secondary !important;
}


/* ==========================================================================
3. Input placeholders
========================================================================== */

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $placeholder;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholder;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $placeholder;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $placeholder;
}


/* ==========================================================================
4. Window scrollbar
========================================================================== */

*::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
    width: 10px;
}

*::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2)
}